import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "@redq/reuse-modal/es/index.css";
import SEO from "../../components/seo";
import { Link } from "gatsby";

import { Section, Title, Text, Box } from "../../components/Blog/Core";

import PostDetails from "../../sections/blog/PostDetails";
import Sidebar from "../../sections/blog/Sidebar";
import Layout from "../../components/Blog/Layout";
import { convertDateToText } from "../../lib/dateHelper";

const BlogDetails = ({ pageContext }) => {
  const { post, blog } = pageContext;

  return (
    <React.Fragment>
      <SEO title={`${post.title} - MARK AI`} description={post.excerpt} />
      <Layout>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="12">
                <Title variant="hero">{post.title}</Title>
                <Box className="d-flex justify-content-center">
                  <Text mr={3}>
                    <Link to="/">{convertDateToText(post.date)} </Link>
                  </Text>
                  <Text mr={3}>
                    <Link to="/">Advertising</Link>
                  </Text>
                  <Text>
                    <Link to="/">MARK AI</Link>
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pb-0">
          <Container>
            <Row>
              <Col lg="8" className="mb-5">
                <PostDetails post={post} />
              </Col>
              <Col lg="4" className="">
                <Sidebar data={blog} />
              </Col>
            </Row>
          </Container>
        </Section>
      </Layout>
    </React.Fragment>
  );
};
export default BlogDetails;
